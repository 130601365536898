<template>
  <div class="banner-interactive-card">
    <div class="banner-interactive-card__wrapper">
      <div class="banner-interactive-card__card">
        <Column>
          <slot name="banner-interactive-card-title"></slot>
          <slot name="banner-interactive-card-subtitle"></slot>
          <slot name="banner-interactive-card-description"></slot>
          <slot/>
        </Column>
      </div>
      <div v-if="$laptop">
        <div :key="point.id"
             :style="{top: `${point.top}px`, left: `${point.left}px`}"
             class="banner-interactive-card__point"
             v-for="point in points"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerInteractiveCard',
  data() {
    return {
      points: [{
        top: 153,
        left: 506,
      }, {
        top: 88,
        left: 727,
      }, {
        top: 238,
        left: 767,
      }, {
        top: 100,
        left: 868,
      }, {
        top: 254,
        left: 1004,
      }],
    };
  },
};
</script>
